//romandate.js <c> MMXXIII, MMXXIV aniy, Omnia jura reservata. aniy@aniy.jp

module.exports = function DiesRomani(dt, flag = 0){
	const romandate = {
		"01-01": "Kalendae Januariae",
		"01-02": "Dies quartus ante Nonas Januarias",
		"01-03": "Dies tertius ante Nonas Januarias",
		"01-04": "Dies pridianus Nonarum Januariarum",
		"01-05": "Nonae Januariae",
		"01-06": "Dies octavus ante Idus Januarias",
		"01-07": "Dies septimus ante Idus Januarias",
		"01-08": "Dies sextus ante Idus Januarias",
		"01-09": "Dies quintus ante Idus Januarias",
		"01-10": "Dies quartus ante Idus Januarias",
		"01-11": "Dies tertius ante Idus Januarias",
		"01-12": "Dies pridianus Iduum Januariarum",
		"01-13": "Idus Januariae",
		"01-14": "Dies undevicesimus ante Kalendas Februarias",
		"01-15": "Dies duodevicesimus ante Kalendas Februarias",
		"01-16": "Dies septimus decimus ante Kalendas Februarias",
		"01-17": "Dies sextus decimus ante Kalendas Februarias",
		"01-18": "Dies quintus decimus ante Kalendas Februarias",
		"01-19": "Dies quartus decimus ante Kalendas Februarias",
		"01-20": "Dies tertius decimus ante Kalendas Februarias",
		"01-21": "Dies duodecimus ante Kalendas Februarias",
		"01-22": "Dies undecimus ante Kalendas Februarias",
		"01-23": "Dies decimus ante Kalendas Februarias",
		"01-24": "Dies nonus ante Kalendas Februarias",
		"01-25": "Dies octavus ante Kalendas Februarias",
		"01-26": "Dies septimus ante Kalendas Februarias",
		"01-27": "Dies sextus ante Kalendas Februarias",
		"01-28": "Dies quintus ante Kalendas Februarias",
		"01-29": "Dies quartus ante Kalendas Februarias",
		"01-30": "Dies tertius ante Kalendas Februarias",
		"01-31": "Dies pridianus Kalendarum Februariarum",
		"02-01": "Kalendae Februariae",
		"02-02": "Dies quartus ante Nonas Februarias",
		"02-03": "Dies tertius ante Nonas Februarias",
		"02-04": "Dies pridianus Nonarum Februariarum",
		"02-05": "Nonae Februariae",
		"02-06": "Dies octavus ante Idus Februarias",
		"02-07": "Dies septimus ante Idus Februarias",
		"02-08": "Dies sextus ante Idus Februarias",
		"02-09": "Dies quintus ante Idus Februarias",
		"02-10": "Dies quartus ante Idus Februarias",
		"02-11": "Dies tertius ante Idus Februarias",
		"02-12": "Dies pridianus Iduum Februariarum",
		"02-13": "Idus Februariae",
		"02-14": "Dies sextus decimus ante Kalendas Martias",
		"02-15": "Dies quintus decimus ante Kalendas Martias",
		"02-16": "Dies quartus decimus ante Kalendas Martias",
		"02-17": "Dies tertius decimus ante Kalendas Martias",
		"02-18": "Dies duodecimus ante Kalendas Martias",
		"02-19": "Dies undecimus ante Kalendas Martias",
		"02-20": "Dies decimus ante Kalendas Martias",
		"02-21": "Dies nonus ante Kalendas Martias",
		"02-22": "Dies octavus ante Kalendas Martias",
		"02-23": "Dies septimus ante Kalendas Martias",
		"02-24": "Dies sextus ante Kalendas Martias",
		"02-25": "Dies quintus ante Kalendas Martias",
		"02-26": "Dies quartus ante Kalendas Martias",
		"02-27": "Dies tertius ante Kalendas Martias",
		"02-28": "Dies pridianus Kalendarum Martiarum",
		"03-01": "Kalendae Martiae",
		"03-02": "Dies sextus ante Nonas Martias",
		"03-03": "Dies quintus ante Nonas Martias",
		"03-04": "Dies quartus ante Nonas Martias",
		"03-05": "Dies tertius ante Nonas Martias",
		"03-06": "Dies pridianus Nonarum Martiarum",
		"03-07": "Nonae Martiae",
		"03-08": "Dies octavus ante Idus Martias",
		"03-09": "Dies septimus ante Idus Martias",
		"03-10": "Dies sextus ante Idus Martias",
		"03-11": "Dies quintus ante Idus Martias",
		"03-12": "Dies quartus ante Idus Martias",
		"03-13": "Dies tertius ante Idus Martias",
		"03-14": "Dies pridianus Iduum Martiarum",
		"03-15": "Idus Martiae",
		"03-16": "Dies septimus decimus ante Kalendas Apriles",
		"03-17": "Dies sextus decimus ante Kalendas Apriles",
		"03-18": "Dies quintus decimus ante Kalendas Apriles",
		"03-19": "Dies quartus decimus ante Kalendas Apriles",
		"03-20": "Dies tertius decimus ante Kalendas Apriles",
		"03-21": "Dies duodecimus ante Kalendas Apriles",
		"03-22": "Dies undecimus ante Kalendas Apriles",
		"03-23": "Dies decimus ante Kalendas Apriles",
		"03-24": "Dies nonus ante Kalendas Apriles",
		"03-25": "Dies octavus ante Kalendas Apriles",
		"03-26": "Dies septimus ante Kalendas Apriles",
		"03-27": "Dies sextus ante Kalendas Apriles",
		"03-28": "Dies quintus ante Kalendas Apriles",
		"03-29": "Dies quartus ante Kalendas Apriles",
		"03-30": "Dies tertius ante Kalendas Apriles",
		"03-31": "Dies pridianus Kalendarum Aprilium",
		"04-01": "Kalendae Apriles",
		"04-02": "Dies quartus ante Nonas Apriles",
		"04-03": "Dies tertius ante Nonas Apriles",
		"04-04": "Dies pridianus Nonarum Aprilium",
		"04-05": "Nonae Apriles",
		"04-06": "Dies octavus ante Idus Apriles",
		"04-07": "Dies septimus ante Idus Apriles",
		"04-08": "Dies sextus ante Idus Apriles",
		"04-09": "Dies quintus ante Idus Apriles",
		"04-10": "Dies quartus ante Idus Apriles",
		"04-11": "Dies tertius ante Idus Apriles",
		"04-12": "Dies pridianus Iduum Aprilium",
		"04-13": "Idus Apriles",
		"04-14": "Dies duodevicesimus ante Kalendas Maias",
		"04-15": "Dies septimus decimus ante Kalendas Maias",
		"04-16": "Dies sextus decimus ante Kalendas Maias",
		"04-17": "Dies quintus decimus ante Kalendas Maias",
		"04-18": "Dies quartus decimus ante Kalendas Maias",
		"04-19": "Dies tertius decimus ante Kalendas Maias",
		"04-20": "Dies duodecimus ante Kalendas Maias",
		"04-21": "Dies undecimus ante Kalendas Maias",
		"04-22": "Dies decimus ante Kalendas Maias",
		"04-23": "Dies nonus ante Kalendas Maias",
		"04-24": "Dies octavus ante Kalendas Maias",
		"04-25": "Dies septimus ante Kalendas Maias",
		"04-26": "Dies sextus ante Kalendas Maias",
		"04-27": "Dies quintus ante Kalendas Maias",
		"04-28": "Dies quartus ante Kalendas Maias",
		"04-29": "Dies tertius ante Kalendas Maias",
		"04-30": "Dies pridianus Kalendarum Maiarum",
		"05-01": "Kalendae Maiae",
		"05-02": "Dies sextus ante Nonas Maias",
		"05-03": "Dies quintus ante Nonas Maias",
		"05-04": "Dies quartus ante Nonas Maias",
		"05-05": "Dies tertius ante Nonas Maias",
		"05-06": "Dies pridianus Nonarum Maiarum",
		"05-07": "Nonae Maiae",
		"05-08": "Dies octavus ante Idus Maias",
		"05-09": "Dies septimus ante Idus Maias",
		"05-10": "Dies sextus ante Idus Maias",
		"05-11": "Dies quintus ante Idus Maias",
		"05-12": "Dies quartus ante Idus Maias",
		"05-13": "Dies tertius ante Idus Maias",
		"05-14": "Dies pridianus Iduum Maiarum",
		"05-15": "Idus Maiae",
		"05-16": "Dies septimus decimus ante Kalendas Junias",
		"05-17": "Dies sextus decimus ante Kalendas Junias",
		"05-18": "Dies quintus decimus ante Kalendas Junias",
		"05-19": "Dies quartus decimus ante Kalendas Junias",
		"05-20": "Dies tertius decimus ante Kalendas Junias",
		"05-21": "Dies duodecimus ante Kalendas Junias",
		"05-22": "Dies undecimus ante Kalendas Junias",
		"05-23": "Dies decimus ante Kalendas Junias",
		"05-24": "Dies nonus ante Kalendas Junias",
		"05-25": "Dies octavus ante Kalendas Junias",
		"05-26": "Dies septimus ante Kalendas Junias",
		"05-27": "Dies sextus ante Kalendas Junias",
		"05-28": "Dies quintus ante Kalendas Junias",
		"05-29": "Dies quartus ante Kalendas Junias",
		"05-30": "Dies tertius ante Kalendas Junias",
		"05-31": "Dies pridianus Kalendarum Juniarum",
		"06-01": "Kalendae Juniae",
		"06-02": "Dies quartus ante Nonas Junias",
		"06-03": "Dies tertius ante Nonas Junias",
		"06-04": "Dies pridianus Nonarum Juniarum",
		"06-05": "Nonae Juniae",
		"06-06": "Dies octavus ante Idus Junias",
		"06-07": "Dies septimus ante Idus Junias",
		"06-08": "Dies sextus ante Idus Junias",
		"06-09": "Dies quintus ante Idus Junias",
		"06-10": "Dies quartus ante Idus Junias",
		"06-11": "Dies tertius ante Idus Junias",
		"06-12": "Dies pridianus Iduum Juniarum",
		"06-13": "Idus Juniae",
		"06-14": "Dies duodevicesimus ante Kalendas Julias",
		"06-15": "Dies septimus decimus ante Kalendas Julias",
		"06-16": "Dies sextus decimus ante Kalendas Julias",
		"06-17": "Dies quintus decimus ante Kalendas Julias",
		"06-18": "Dies quartus decimus ante Kalendas Julias",
		"06-19": "Dies tertius decimus ante Kalendas Julias",
		"06-20": "Dies duodecimus ante Kalendas Julias",
		"06-21": "Dies undecimus ante Kalendas Julias",
		"06-22": "Dies decimus ante Kalendas Julias",
		"06-23": "Dies nonus ante Kalendas Julias",
		"06-24": "Dies octavus ante Kalendas Julias",
		"06-25": "Dies septimus ante Kalendas Julias",
		"06-26": "Dies sextus ante Kalendas Julias",
		"06-27": "Dies quintus ante Kalendas Julias",
		"06-28": "Dies quartus ante Kalendas Julias",
		"06-29": "Dies tertius ante Kalendas Julias",
		"06-30": "Dies pridianus Kalendarum Juliarum",
		"07-01": "Kalendae Juliae",
		"07-02": "Dies sextus ante Nonas Julias",
		"07-03": "Dies quintus ante Nonas Julias",
		"07-04": "Dies quartus ante Nonas Julias",
		"07-05": "Dies tertius ante Nonas Julias",
		"07-06": "Dies pridianus Nonarum Juliarum",
		"07-07": "Nonae Juliae",
		"07-08": "Dies octavus ante Idus Julias",
		"07-09": "Dies septimus ante Idus Julias",
		"07-10": "Dies sextus ante Idus Julias",
		"07-11": "Dies quintus ante Idus Julias",
		"07-12": "Dies quartus ante Idus Julias",
		"07-13": "Dies tertius ante Idus Julias",
		"07-14": "Dies pridianus Iduum Juliarum",
		"07-15": "Idus Juliae",
		"07-16": "Dies septimus decimus ante Kalendas Augustas",
		"07-17": "Dies sextus decimus ante Kalendas Augustas",
		"07-18": "Dies quintus decimus ante Kalendas Augustas",
		"07-19": "Dies quartus decimus ante Kalendas Augustas",
		"07-20": "Dies tertius decimus ante Kalendas Augustas",
		"07-21": "Dies duodecimus ante Kalendas Augustas",
		"07-22": "Dies undecimus ante Kalendas Augustas",
		"07-23": "Dies decimus ante Kalendas Augustas",
		"07-24": "Dies nonus ante Kalendas Augustas",
		"07-25": "Dies octavus ante Kalendas Augustas",
		"07-26": "Dies septimus ante Kalendas Augustas",
		"07-27": "Dies sextus ante Kalendas Augustas",
		"07-28": "Dies quintus ante Kalendas Augustas",
		"07-29": "Dies quartus ante Kalendas Augustas",
		"07-30": "Dies tertius ante Kalendas Augustas",
		"07-31": "Dies pridianus Kalendarum Augustarum",
		"08-01": "Kalendae Augustae",
		"08-02": "Dies quartus ante Nonas Augustas",
		"08-03": "Dies tertius ante Nonas Augustas",
		"08-04": "Dies pridianus Nonarum Augustarum",
		"08-05": "Nonae Augustae",
		"08-06": "Dies octavus ante Idus Augustas",
		"08-07": "Dies septimus ante Idus Augustas",
		"08-08": "Dies sextus ante Idus Augustas",
		"08-09": "Dies quintus ante Idus Augustas",
		"08-10": "Dies quartus ante Idus Augustas",
		"08-11": "Dies tertius ante Idus Augustas",
		"08-12": "Dies pridianus Iduum Augustarum",
		"08-13": "Idus Augustae",
		"08-14": "Dies undevicesimus ante Kalendas Septembres",
		"08-15": "Dies duodevicesimus ante Kalendas Septembres",
		"08-16": "Dies septimus decimus ante Kalendas Septembres",
		"08-17": "Dies sextus decimus ante Kalendas Septembres",
		"08-18": "Dies quintus decimus ante Kalendas Septembres",
		"08-19": "Dies quartus decimus ante Kalendas Septembres",
		"08-20": "Dies tertius decimus ante Kalendas Septembres",
		"08-21": "Dies duodecimus ante Kalendas Septembres",
		"08-22": "Dies undecimus ante Kalendas Septembres",
		"08-23": "Dies decimus ante Kalendas Septembres",
		"08-24": "Dies nonus ante Kalendas Septembres",
		"08-25": "Dies octavus ante Kalendas Septembres",
		"08-26": "Dies septimus ante Kalendas Septembres",
		"08-27": "Dies sextus ante Kalendas Septembres",
		"08-28": "Dies quintus ante Kalendas Septembres",
		"08-29": "Dies quartus ante Kalendas Septembres",
		"08-30": "Dies tertius ante Kalendas Septembres",
		"08-31": "Dies pridianus Kalendarum Septembrium",
		"09-01": "Kalendae Septembres",
		"09-02": "Dies quartus ante Nonas Septembres",
		"09-03": "Dies tertius ante Nonas Septembres",
		"09-04": "Dies pridianus Nonarum Septembrium",
		"09-05": "Nonae Septembres",
		"09-06": "Dies octavus ante Idus Septembres",
		"09-07": "Dies septimus ante Idus Septembres",
		"09-08": "Dies sextus ante Idus Septembres",
		"09-09": "Dies quintus ante Idus Septembres",
		"09-10": "Dies quartus ante Idus Septembres",
		"09-11": "Dies tertius ante Idus Septembres",
		"09-12": "Dies pridianus Iduum Septembrium",
		"09-13": "Idus Septembres",
		"09-14": "Dies duodevicesimus ante Kalendas Octobres",
		"09-15": "Dies septimus decimus ante Kalendas Octobres",
		"09-16": "Dies sextus decimus ante Kalendas Octobres",
		"09-17": "Dies quintus decimus ante Kalendas Octobres",
		"09-18": "Dies quartus decimus ante Kalendas Octobres",
		"09-19": "Dies tertius decimus ante Kalendas Octobres",
		"09-20": "Dies duodecimus ante Kalendas Octobres",
		"09-21": "Dies undecimus ante Kalendas Octobres",
		"09-22": "Dies decimus ante Kalendas Octobres",
		"09-23": "Dies nonus ante Kalendas Octobres",
		"09-24": "Dies octavus ante Kalendas Octobres",
		"09-25": "Dies septimus ante Kalendas Octobres",
		"09-26": "Dies sextus ante Kalendas Octobres",
		"09-27": "Dies quintus ante Kalendas Octobres",
		"09-28": "Dies quartus ante Kalendas Octobres",
		"09-29": "Dies tertius ante Kalendas Octobres",
		"09-30": "Dies pridianus Kalendarum Octobrium",
		"10-01": "Kalendae Octobres",
		"10-02": "Dies sextus ante Nonas Octobres",
		"10-03": "Dies quintus ante Nonas Octobres",
		"10-04": "Dies quartus ante Nonas Octobres",
		"10-05": "Dies tertius ante Nonas Octobres",
		"10-06": "Dies pridianus Nonarum Octobrium",
		"10-07": "Nonae Octobres",
		"10-08": "Dies octavus ante Idus Octobres",
		"10-09": "Dies septimus ante Idus Octobres",
		"10-10": "Dies sextus ante Idus Octobres",
		"10-11": "Dies quintus ante Idus Octobres",
		"10-12": "Dies quartus ante Idus Octobres",
		"10-13": "Dies tertius ante Idus Octobres",
		"10-14": "Dies pridianus Iduum Octobrium",
		"10-15": "Idus Octobres",
		"10-16": "Dies septimus decimus ante Kalendas Novembres",
		"10-17": "Dies sextus decimus ante Kalendas Novembres",
		"10-18": "Dies quintus decimus ante Kalendas Novembres",
		"10-19": "Dies quartus decimus ante Kalendas Novembres",
		"10-20": "Dies tertius decimus ante Kalendas Novembres",
		"10-21": "Dies duodecimus ante Kalendas Novembres",
		"10-22": "Dies undecimus ante Kalendas Novembres",
		"10-23": "Dies decimus ante Kalendas Novembres",
		"10-24": "Dies nonus ante Kalendas Novembres",
		"10-25": "Dies octavus ante Kalendas Novembres",
		"10-26": "Dies septimus ante Kalendas Novembres",
		"10-27": "Dies sextus ante Kalendas Novembres",
		"10-28": "Dies quintus ante Kalendas Novembres",
		"10-29": "Dies quartus ante Kalendas Novembres",
		"10-30": "Dies tertius ante Kalendas Novembres",
		"10-31": "Dies pridianus Kalendarum Novembrium",
		"11-01": "Kalendae Novembres",
		"11-02": "Dies quartus ante Nonas Novembres",
		"11-03": "Dies tertius ante Nonas Novembres",
		"11-04": "Dies pridianus Nonarum Novembrium",
		"11-05": "Nonae Novembres",
		"11-06": "Dies octavus ante Idus Novembres",
		"11-07": "Dies septimus ante Idus Novembres",
		"11-08": "Dies sextus ante Idus Novembres",
		"11-09": "Dies quintus ante Idus Novembres",
		"11-10": "Dies quartus ante Idus Novembres",
		"11-11": "Dies tertius ante Idus Novembres",
		"11-12": "Dies pridianus Iduum Novembrium",
		"11-13": "Idus Novembres",
		"11-14": "Dies duodevicesimus ante Kalendas Decembres",
		"11-15": "Dies septimus decimus ante Kalendas Decembres",
		"11-16": "Dies sextus decimus ante Kalendas Decembres",
		"11-17": "Dies quintus decimus ante Kalendas Decembres",
		"11-18": "Dies quartus decimus ante Kalendas Decembres",
		"11-19": "Dies tertius decimus ante Kalendas Decembres",
		"11-20": "Dies duodecimus ante Kalendas Decembres",
		"11-21": "Dies undecimus ante Kalendas Decembres",
		"11-22": "Dies decimus ante Kalendas Decembres",
		"11-23": "Dies nonus ante Kalendas Decembres",
		"11-24": "Dies octavus ante Kalendas Decembres",
		"11-25": "Dies septimus ante Kalendas Decembres",
		"11-26": "Dies sextus ante Kalendas Decembres",
		"11-27": "Dies quintus ante Kalendas Decembres",
		"11-28": "Dies quartus ante Kalendas Decembres",
		"11-29": "Dies tertius ante Kalendas Decembres",
		"11-30": "Dies pridianus Kalendarum Decembrium",
		"12-01": "Kalendae Decembres",
		"12-02": "Dies quartus ante Nonas Decembres",
		"12-03": "Dies tertius ante Nonas Decembres",
		"12-04": "Dies pridianus Nonarum Decembrium",
		"12-05": "Nonae Decembres",
		"12-06": "Dies octavus ante Idus Decembres",
		"12-07": "Dies septimus ante Idus Decembres",
		"12-08": "Dies sextus ante Idus Decembres",
		"12-09": "Dies quintus ante Idus Decembres",
		"12-10": "Dies quartus ante Idus Decembres",
		"12-11": "Dies tertius ante Idus Decembres",
		"12-12": "Dies pridianus Iduum Decembrium",
		"12-13": "Idus Decembres",
		"12-14": "Dies undevicesimus ante Kalendas Januarias",
		"12-15": "Dies duodevicesimus ante Kalendas Januarias",
		"12-16": "Dies septimus decimus ante Kalendas Januarias",
		"12-17": "Dies sextus decimus ante Kalendas Januarias",
		"12-18": "Dies quintus decimus ante Kalendas Januarias",
		"12-19": "Dies quartus decimus ante Kalendas Januarias",
		"12-20": "Dies tertius decimus ante Kalendas Januarias",
		"12-21": "Dies duodecimus ante Kalendas Januarias",
		"12-22": "Dies undecimus ante Kalendas Januarias",
		"12-23": "Dies decimus ante Kalendas Januarias",
		"12-24": "Dies nonus ante Kalendas Januarias",
		"12-25": "Dies octavus ante Kalendas Januarias",
		"12-26": "Dies septimus ante Kalendas Januarias",
		"12-27": "Dies sextus ante Kalendas Januarias",
		"12-28": "Dies quintus ante Kalendas Januarias",
		"12-29": "Dies quartus ante Kalendas Januarias",
		"12-30": "Dies tertius ante Kalendas Januarias",
		"12-31": "Dies pridianus Kalendarum Januariarum"
	};

	const romandateleap = {
		"02-01": "Kalendae Februariae",
		"02-02": "Dies quartus ante Nonas Februarias",
		"02-03": "Dies tertius ante Nonas Februarias",
		"02-04": "Dies pridianus Nonarum Februariarum",
		"02-05": "Nonae Februariae",
		"02-06": "Dies octavus ante Idus Februarias",
		"02-07": "Dies septimus ante Idus Februarias",
		"02-08": "Dies sextus ante Idus Februarias",
		"02-09": "Dies quintus ante Idus Februarias",
		"02-10": "Dies quartus ante Idus Februarias",
		"02-11": "Dies tertius ante Idus Februarias",
		"02-12": "Dies pridianus Iduum Februariarum",
		"02-13": "Idus Februariae",
		"02-14": "Dies sextus decimus ante Kalendas Martias",
		"02-15": "Dies quintus decimus ante Kalendas Martias",
		"02-16": "Dies quartus decimus ante Kalendas Martias",
		"02-17": "Dies tertius decimus ante Kalendas Martias",
		"02-18": "Dies duodecimus ante Kalendas Martias",
		"02-19": "Dies undecimus ante Kalendas Martias",
		"02-20": "Dies decimus ante Kalendas Martias",
		"02-21": "Dies nonus ante Kalendas Martias",
		"02-22": "Dies octavus ante Kalendas Martias",
		"02-23": "Dies septimus ante Kalendas Martias",
		"02-24": "Dies sextus ante Kalendas Martias",
		"02-25": "Secundus dies sextus ante Kalendas Martias",
		"02-26": "Dies quintus ante Kalendas Martias",
		"02-27": "Dies quartus ante Kalendas Martias",
		"02-28": "Dies tertius ante Kalendas Martias",
		"02-29": "Dies pridianus Kalendarum Martiarum"
	};

	const romanyear = {
		"2000": "MM",
		"2001": "MMI",
		"2002": "MMII",
		"2003": "MMIII",
		"2004": "MMIV",
		"2005": "MMV",
		"2006": "MMVI",
		"2007": "MMVII",
		"2008": "MMVIII",
		"2009": "MMIX",
		"2010": "MMX",
		"2011": "MMXI",
		"2012": "MMXII",
		"2013": "MMXIII",
		"2014": "MMXIV",
		"2015": "MMXV",
		"2016": "MMXVI",
		"2017": "MMXVII",
		"2018": "MMXVIII",
		"2019": "MMXIX",
		"2020": "MMXX",
		"2021": "MMXXI",
		"2022": "MMXXII",
		"2023": "MMXXIII",
		"2024": "MMXXIV",
		"2025": "MMXXV",
		"2026": "MMXXVI",
		"2027": "MMXXVII",
		"2028": "MMXXVIII",
		"2029": "MMXXIX",
		"2030": "MMXXX",
		"2031": "MMXXXI",
		"2032": "MMXXXII",
		"2033": "MMXXXIII",
		"2034": "MMXXXIV",
		"2035": "MMXXXV",
		"2036": "MMXXXVI",
		"2037": "MMXXXVII",
		"2038": "MMXXXVIII",
		"2039": "MMXXXIX",
		"2040": "MMXL",
		"2041": "MMXLI",
		"2042": "MMXLII",
		"2043": "MMXLIII",
		"2044": "MMXLIV",
		"2045": "MMXLV",
		"2046": "MMXLVI",
		"2047": "MMXLVII",
		"2048": "MMXLVIII",
		"2049": "MMXLIX",
		"2050": "MML",
		"2051": "MMLI",
		"2052": "MMLII",
		"2053": "MMLIII",
		"2054": "MMLIV",
		"2055": "MMLV",
		"2056": "MMLVI",
		"2057": "MMLVII",
		"2058": "MMLVIII",
		"2059": "MMLIX",
		"2060": "MMLX",
		"2061": "MMLXI",
		"2062": "MMLXII",
		"2063": "MMLXIII",
		"2064": "MMLXIV",
		"2065": "MMLXV",
		"2066": "MMLXVI",
		"2067": "MMLXVII",
		"2068": "MMLXVIII",
		"2069": "MMLXIX",
		"2070": "MMLXX",
		"2071": "MMLXXI",
		"2072": "MMLXXII",
		"2073": "MMLXXIII",
		"2074": "MMLXXIV",
		"2075": "MMLXXV",
		"2076": "MMLXXVI",
		"2077": "MMLXXVII",
		"2078": "MMLXXVIII",
		"2079": "MMLXXIX",
		"2080": "MMLXXX",
		"2081": "MMLXXXI",
		"2082": "MMLXXXII",
		"2083": "MMLXXXIII",
		"2084": "MMLXXXIV",
		"2085": "MMLXXXV",
		"2086": "MMLXXXVI",
		"2087": "MMLXXXVII",
		"2088": "MMLXXXVIII",
		"2089": "MMLXXXIX",
		"2090": "MMXC",
		"2091": "MMXCI",
		"2092": "MMXCII",
		"2093": "MMXCIII",
		"2094": "MMXCIV",
		"2095": "MMXCV",
		"2096": "MMXCVI",
		"2097": "MMXCVII",
		"2098": "MMXCVIII",
		"2099": "MMXCIX",
		"2100": "MMC"
	};

	const date = new Date(dt);
	const year = date.getFullYear().toString();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = (date.getDate()).toString().padStart(2, "0");
	const hour = (date.getHours()).toString().padStart(2, "0");
	const minute = (date.getMinutes()).toString().padStart(2, "0");
	const time = hour + ":" + minute
	const monthdate = month + "-" + day
	const romandatestr = (Number(year) % 4 > 0 || month != "02") ? romandate[monthdate] : romandateleap[monthdate];
	let datestr;

	if(flag == 0){
		datestr = romandatestr + ", " + romanyear[year];
	}else if(flag == 1){
		datestr = romandatestr + ", " + romanyear[year] + ", " + time;
	}else if(flag == 2){
		datestr = day + "/" + month + "/" + year;
	}else{
		datestr = day + "/" + month + "/" + year + " " + time;
	}

	return datestr;
}
